<template>
  <div :class="$style.TheBox">
    <slot />
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" module>
.TheBox {
  position: relative;
  max-width: 58rem;
  min-height: 46rem;
  margin: 0 auto;
  background: $body-color;
  padding: 7rem 2.8rem 6rem;

  @include respond-to(xs) {
    min-height: 40px;
    padding: 6rem 3rem 5.5rem;
  }

  &::before {
    content: "";
    position: absolute;
    inset: 2px;
    z-index: -1;
    min-height: calc(46rem - 4px);
    background: $bg-color;

    @include respond-to(xs) {
      min-height: calc(40px - 4px);
    }
  }
}

.TheBox,
.TheBox::before {
  clip-path: polygon(
    0% 100%,
    0% 5%,
    5% 0%,
    45% 0%,
    52% 4%,
    85% 4%,
    92% 0%,
    100% 0%,
    100% 100%
  );

  @include respond-to(xs) {
    clip-path: polygon(
      0% 100%,
      0% 3%,
      5% 0%,
      45% 0%,
      52% 3%,
      85% 3%,
      92% 0%,
      100% 0%,
      100% 100%
    );
  }
}
</style>
